import { useCallback, useMemo } from "react";
import { OverviewType, useAppDispatch, useAppSelector } from "../../app";
import {
  updateUserArchiveOrderColumns,
  updateUserOverviewOrderColumns,
} from "../../user/store/userSlice";
import { OrderColumnItem } from "../interfaces/overview";
import { Translation } from "../../configMap";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { REQUIRED_COLUMNS } from "../constants";
import { useTranslation } from "react-i18next";
import useOverviewMapColumns from "./useOverviewMapColumns";
import useUserSettings from "../../user/hooks/useUserSettings";
import { calculateTriggerHasHorizontalScroll } from "../store/documentSetSlice";

const useOrderColumns = () => {
  const dispatch = useAppDispatch();

  const { overviewType } = useAppSelector((state) => state.documentSetsReducer);
  const { userSettings, user } = useAppSelector((state) => state.userReducer);

  const { archiveOrderColumns, overviewOrderColumns } = userSettings;

  const { updateUserSettings } = useUserSettings();
  const { tableItems, orderItems } = useOverviewMapColumns();

  const {
    t,
    i18n: { language },
  } = useTranslation("overview");

  const updateOrderColumns = useCallback(
    (columns: Array<OrderColumnItem>) => {
      if (overviewType === OverviewType.Archived) {
        dispatch(updateUserArchiveOrderColumns(columns));
        const updated = {
          ...userSettings,
          archiveOrderColumns: columns,
        };

        updateUserSettings(user!, updated);
      } else {
        dispatch(updateUserOverviewOrderColumns(columns));
        const updated = {
          ...userSettings,
          overviewOrderColumns: columns,
        };

        updateUserSettings(user!, updated);
      }

      dispatch(calculateTriggerHasHorizontalScroll());
    },
    [dispatch, overviewType, user, userSettings, updateUserSettings]
  );

  const parseTitle = useCallback(
    (translations: Translation | null, language: string): string => {
      if (!translations) {
        return "";
      }

      if (typeof translations === "object") {
        return getInLanguageOrDefault(translations, language);
      }

      return "";
    },
    []
  );

  const userOrderedColumns = useMemo(() => {
    if (overviewType === OverviewType.Archived) {
      return archiveOrderColumns || [];
    }

    return overviewOrderColumns || [];
  }, [archiveOrderColumns, overviewOrderColumns, overviewType]);

  const filteredColumns: Array<OrderColumnItem> = useMemo(() => {
    const filterOutKeys = Object.keys(REQUIRED_COLUMNS);
    return orderItems
      .map((item) => {
        if (filterOutKeys.includes(item.key)) {
          return null;
        }

        const found = userOrderedColumns.find((i) => i.key === item.key);
        if (found) {
          return {
            title: parseTitle(item.translations, language),
            key: item.key,
            show: found.show,
          };
        }

        return {
          title: parseTitle(item.translations, language),
          key: item.key,
          show: true,
        };
      })
      .filter((item): item is OrderColumnItem => item !== null);
  }, [orderItems, userOrderedColumns, parseTitle, language]);

  const titlePopover = useMemo(() => {
    if (overviewType === OverviewType.Archived) {
      return t("orderColumns.titleArchive");
    }

    return t("orderColumns.titleOverview");
  }, [t, overviewType]);

  const helpTooltip = useMemo(() => {
    if (overviewType === OverviewType.Archived) {
      return t("orderColumns.helpTooltipArchive");
    }

    return t("orderColumns.helpTooltipOverview");
  }, [t, overviewType]);

  return {
    updateOrderColumns,
    overviewType,
    filteredColumns,
    tableItems,
    titlePopover,
    helpTooltip,
  };
};

export default useOrderColumns;
