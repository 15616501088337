import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import { ParentEntity } from "../../documentSet/interfaces/api";

type Props = {
  parentEntity: ParentEntity | undefined;
};

// TODO: Only works in case of a single non multi groupblock entity right now

const getParentEntity = (_: RootState, props: Props) => props.parentEntity;

const selectEntityParentForCategorization = createSelector(
  [selectAnnotationReducer, getParentEntity],
  (annotations, parentEntity): string | null => {
    if (!parentEntity) {
      return null;
    }

    if (!annotations.length) {
      return null;
    }

    const parentEntityId = parentEntity.id;

    const found = annotations.find(
      (annotation) => annotation.entity.id === parentEntityId
    );

    if (found) {
      if (parentEntity.useNormalizedOnly) {
        return found.entityAnnotationNormalization?.normalizedValue || null;
      }

      return found.values.join(" ");
    }

    return null;
  }
);

export default selectEntityParentForCategorization;
