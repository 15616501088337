import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentSetData } from "../";
import { OverviewType } from "../../app";
import { ChangeOverviewType } from "../interfaces/overview";
import { DocumentSetFetchingErrorType } from "../interfaces/documentSet";

interface DocumentSetState {
  documentSets: Array<DocumentSetData>;
  numberOfPages: number;
  totalNumberOfDocumentSets: number;
  activeDocumentSet?: DocumentSetData;
  overviewType: OverviewType | null;
  loading: boolean;
  selectedRows: Array<string>;
  fetchDocumentSetsTrigger: boolean;
  fetchingErrorType?: DocumentSetFetchingErrorType;
  hasColumnItemsFetchingError?: boolean;
  triggerHasHorizontalScroll: boolean;
}

const initialState: DocumentSetState = {
  documentSets: [],
  numberOfPages: 0,
  totalNumberOfDocumentSets: 0,
  activeDocumentSet: undefined,
  overviewType: null,
  loading: true,
  selectedRows: [],
  fetchDocumentSetsTrigger: false,
  fetchingErrorType: undefined,
  hasColumnItemsFetchingError: undefined,
  triggerHasHorizontalScroll: false,
};

export const documentSetSlice = createSlice({
  name: "documentSets",
  initialState,
  reducers: {
    fetchedDocumentSets: (state, action: PayloadAction<DocumentSetState>) => {
      const {
        overviewType,
        documentSets,
        numberOfPages,
        totalNumberOfDocumentSets,
        fetchingErrorType,
      } = action.payload;

      if (state.overviewType === null) {
        state.overviewType = overviewType;
      }

      if (overviewType !== state.overviewType) {
        return;
      }

      state.documentSets = documentSets;
      state.numberOfPages = numberOfPages;
      state.totalNumberOfDocumentSets = totalNumberOfDocumentSets;
      state.loading = false;
      state.fetchingErrorType = fetchingErrorType;
    },
    addDocumentSet: (state, action: PayloadAction<DocumentSetData>) => {
      const documentSet = action.payload;

      state.documentSets.push(documentSet);
    },
    removeDocumentSet: (state, action: PayloadAction<DocumentSetData>) => {
      const documentSet = action.payload;
      state.documentSets = state.documentSets.filter(
        (d) => d.id !== documentSet.id
      );
    },
    removeDocumentSets: (state, action: PayloadAction<Array<string>>) => {
      const documentSets = action.payload;
      state.documentSets = state.documentSets.filter(
        (d) => !documentSets.includes(d.id)
      );
    },
    updateDocumentSet: (state, action: PayloadAction<DocumentSetData>) => {
      const documentSet = action.payload;

      state.documentSets = (state.documentSets || []).map((d) => {
        if (d.id === documentSet.id) {
          return documentSet;
        }
        return d;
      });
    },
    setActiveDocumentSet: (
      state,
      action: PayloadAction<DocumentSetData | undefined>
    ) => {
      state.activeDocumentSet = action.payload;
    },
    changeOverviewType: (state, action: PayloadAction<ChangeOverviewType>) => {
      const { overviewType, hasUserInputs } = action.payload;
      if (state.overviewType === overviewType) {
        return;
      }

      state.overviewType = overviewType;
      state.documentSets = [];
      state.numberOfPages = 0;
      state.totalNumberOfDocumentSets = 0;
      state.loading = hasUserInputs;
      state.selectedRows = [];
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    resetDocumentReducerForOverview: (state) => {
      return {
        ...initialState,
        documentSets: state.documentSets,
        overviewType: state.overviewType,
        numberOfPages: state.numberOfPages,
        totalNumberOfDocumentSets: state.totalNumberOfDocumentSets,
        hasColumnItemsFetchingError: state.hasColumnItemsFetchingError,
      };
    },
    resetDocumentSetsReducer: (state) => {
      return {
        ...initialState,
        hasColumnItemsFetchingError: state.hasColumnItemsFetchingError,
      };
    },
    updateSelectedRow: (state, action: PayloadAction<string>) => {
      const index = state.selectedRows.indexOf(action.payload);
      if (index !== -1) {
        state.selectedRows.splice(index, 1);
      } else {
        state.selectedRows.push(action.payload);
      }
    },
    updateSelectedRows: (state, action: PayloadAction<Array<string>>) => {
      state.selectedRows = action.payload;
    },
    resetSelectedRows: (state) => {
      if (!state.selectedRows.length) {
        return;
      }

      state.selectedRows = [];
    },
    fetchDocumentSetsTrigger: (state) => {
      state.fetchDocumentSetsTrigger = !state.fetchDocumentSetsTrigger;
    },
    setOverviewType: (state, action: PayloadAction<OverviewType>) => {
      state.overviewType = action.payload;
    },
    setFetchingErrorType: (
      state,
      action: PayloadAction<DocumentSetFetchingErrorType | undefined>
    ) => {
      state.fetchingErrorType = action.payload;
    },
    setHasColumnItemsFetchingError: (state, action: PayloadAction<boolean>) => {
      state.hasColumnItemsFetchingError = action.payload;
    },
    calculateTriggerHasHorizontalScroll: (state) => {
      state.triggerHasHorizontalScroll = !state.triggerHasHorizontalScroll;
    },
  },
});

export const {
  addDocumentSet,
  removeDocumentSet,
  removeDocumentSets,
  updateDocumentSet,
  fetchedDocumentSets,
  setActiveDocumentSet,
  changeOverviewType,
  stopLoading,
  resetDocumentReducerForOverview,
  resetDocumentSetsReducer,
  updateSelectedRow,
  updateSelectedRows,
  resetSelectedRows,
  fetchDocumentSetsTrigger,
  setOverviewType,
  setFetchingErrorType,
  setHasColumnItemsFetchingError,
  calculateTriggerHasHorizontalScroll,
} = documentSetSlice.actions;

export default documentSetSlice.reducer;
