import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import {
  DocumentCategoryAnnotation,
  ICategorization,
} from "../../configMap/interfaces/category";
import { KeyByAny } from "../../documentSet/interfaces/overview";
import {
  CategorizationEvent,
  EventCategorizationResponse,
  EventTriggerType,
} from "../interfaces/categorizationEvents";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import { useLazyCallEventQuery } from "../store/metadataApi";
import {
  addLoadingCategorizationIds,
  removeLoadingCategorizationIds,
} from "../store/metadataSlice";
import { notification } from "antd";
import {
  createDcaFromResponse,
  getBodyParams,
  getParamValue,
  getQueryParams,
} from "../utils/categorizationEvents";
import { Annotation } from "../../annotator/interfaces/annotation";

const useCategorizationEvents = () => {
  const dispatch = useAppDispatch();

  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  const attribute = activeDocumentSet?.input.attribute;

  const documentId = useAppSelector(
    (state) => state.documentReducer.activeDocument?.id
  );

  const entityAnnotations = useAppSelector(selectAnnotationReducer);

  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const [callEvent] = useLazyCallEventQuery();

  const setLoadingCategorizations = useCallback(
    (
      isLoading: boolean,
      responseProperties: Array<EventCategorizationResponse>
    ) => {
      const categorizationIds = responseProperties.map((rp) =>
        rp.categorizationId.toLowerCase()
      );

      if (isLoading) {
        dispatch(addLoadingCategorizationIds(categorizationIds));
      } else {
        dispatch(removeLoadingCategorizationIds(categorizationIds));
      }
    },
    [dispatch]
  );

  const triggerEvent = useCallback(
    async (
      event: CategorizationEvent,
      value: string,
      categorization: ICategorization,
      onLoad: boolean = false,
      givenDcas?: Array<DocumentCategoryAnnotation>,
      givenEntityAnnotations?: Array<Annotation>,
      givenAttribute?: KeyByAny,
      givenDocumentId?: string
    ) => {
      const { request, responseProperties, triggerType } = event;
      const { urlParam, bodyParams, queryParams, httpMethod, endpoint } =
        request;

      if (!endpoint || !triggerType || !httpMethod || !responseProperties) {
        return;
      }

      const currentDocumentCategoryAnnotations =
        givenDcas ?? documentCategoryAnnotations;
      const currentEntityAnnotations =
        givenEntityAnnotations ?? entityAnnotations;
      const currentAttribute = givenAttribute ?? attribute;
      const currentDocumentId = givenDocumentId ?? documentId;

      let urlParamValue =
        getParamValue(
          urlParam,
          value,
          categorization.id,
          currentDocumentCategoryAnnotations,
          currentEntityAnnotations,
          currentAttribute
        ) ?? "";
      if (urlParamValue) {
        urlParamValue = `/${urlParamValue}`;
      }

      const bodyParameters = getBodyParams(
        bodyParams,
        value,
        categorization,
        currentDocumentCategoryAnnotations,
        currentEntityAnnotations,
        currentAttribute
      );
      const queryParameters = getQueryParams(
        queryParams,
        value,
        categorization,
        currentDocumentCategoryAnnotations,
        currentEntityAnnotations,
        currentAttribute
      );

      const body = {
        endpoint: `${endpoint}${urlParamValue}${queryParameters}`,
        httpMethod,
        bodyParams: bodyParameters,
      };

      try {
        setLoadingCategorizations(true, responseProperties);
        const response = await callEvent(body).unwrap();
        createDcaFromResponse(
          responseProperties,
          response,
          dispatch,
          currentDocumentId!,
          categorization.categories[0].id,
          currentDocumentCategoryAnnotations,
          onLoad
        );
      } catch (error: any) {
        if (!onLoad) {
          const message =
            error?.data?.error ||
            "We encountered an issue while fetching data for your selection.";
          notification.error({ message });
        }
      } finally {
        setLoadingCategorizations(false, responseProperties);
      }
    },
    [
      callEvent,
      dispatch,
      setLoadingCategorizations,
      documentCategoryAnnotations,
      entityAnnotations,
      attribute,
      documentId,
    ]
  );

  const callEvents = useCallback(
    async (
      eventTriggerType: EventTriggerType,
      categorization: ICategorization,
      value: string = ""
    ) => {
      const events = categorization.events;
      if (!events?.length) {
        return;
      }

      // Filter the events to include only those with the specified trigger type
      const filteredEvents = events.filter(
        (event) => event.triggerType === eventTriggerType
      );

      await Promise.all(
        filteredEvents.map((event) =>
          triggerEvent(event, value, categorization, false)
        )
      );
    },
    [triggerEvent]
  );

  const triggerOnLoadCategorizationEvents = useCallback(
    (
      categorizations: Array<ICategorization> | undefined,
      documentCategoryAnnotations: Array<DocumentCategoryAnnotation>,
      annotations: Array<Annotation>,
      attribute: KeyByAny,
      documentId: string
    ) => {
      if (!categorizations?.length) {
        return;
      }

      for (const categorization of categorizations) {
        const events = categorization.events;
        if (!events?.length) {
          continue;
        }

        const onLoadEvents = events?.filter((event) => event.triggerOnLoad);
        const dca = documentCategoryAnnotations.find(
          (dca) => dca.index === 1 && dca.categorizationId === categorization.id
        );

        if (!onLoadEvents?.length) {
          continue;
        }

        onLoadEvents?.map((event) =>
          triggerEvent(
            event,
            dca?.categoryValue ?? "",
            categorization,
            true,
            documentCategoryAnnotations,
            annotations,
            attribute,
            documentId
          )
        );
      }
    },
    [triggerEvent]
  );

  return {
    callEvents,
    triggerOnLoadCategorizationEvents,
  };
};

export default useCategorizationEvents;
