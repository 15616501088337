import { ICategorization } from "../../configMap/interfaces/category";
import { useAppSelector } from "../../app";
import selectEntityParentForCategorization from "../selectors/selectEntityParentForCategorization";
import { useMemo } from "react";

const useParentEntity = (categorization: ICategorization) => {
  const parentEntity = categorization.search.parentEntity;

  const value = useAppSelector((state) =>
    selectEntityParentForCategorization(state, { parentEntity })
  );

  const attributeFilter = useMemo(() => {
    if (!parentEntity?.type) {
      return undefined;
    }

    if (!value) {
      return undefined;
    }

    return JSON.stringify({ [parentEntity.type]: [value] });
  }, [value, parentEntity]);

  return {
    attributeFilter,
  };
};

export default useParentEntity;
