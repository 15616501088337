import React, { FC } from "react";
import "./HorizontalScrollIndicator.scss";
import useHorizontalScrollIndicator from "../../hooks/useHorizontalScrollIndicator";
import { DocumentSetData } from "../../interfaces/documentSet";

type Props = {
  loading: boolean;
  refTableContainer: any;
  documentSets: Array<DocumentSetData>;
};

const HorizontalScrollIndicator: FC<Props> = ({
  loading,
  refTableContainer,
  documentSets,
}) => {
  const { hasScroll, scrollPercentage } = useHorizontalScrollIndicator(
    refTableContainer,
    documentSets
  );

  if (!hasScroll || loading) {
    return null;
  }

  return (
    <div className="scroll-indicator__container">
      <div className="scroll-indicator__border" />
      <div className="scroll-indicator">
        <span className="scroll-indicator__background" />
        {Array.from({ length: 5 }).map((_, index) => (
          <span
            className="scroll-indicator__horizontal-line"
            style={{ top: `${(index + 1) * 8}px` }}
            key={index}
          />
        ))}
        <span
          className="scroll-indicator__bar"
          style={{ left: `${scrollPercentage}%` }}
        ></span>
      </div>
    </div>
  );
};

export default HorizontalScrollIndicator;
